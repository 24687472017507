import React, { useState, useRef, useEffect } from "react";
import "../../style/main.scss";

import { Tooltip } from "react-bootstrap";
import AsideFilter from "./asideFilter";

import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import { useAccount } from "wagmi";
import config from "../../config";
import AsideFilterExplore from "../explore/asideFilterExplore";
import { networkType } from "../utility/urlStruc";
export default function UserCollectedArtwork(props) {
  const { address } = useAccount();

  const [show, setShow] = useState({});
  const [activePrice, setActivePrice] = useState("listPrice");
  const [priceShow, setPriceShow] = useState(false);
  const dropdownRef = useRef(null);
  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState(null);
  const [tokenId, setTokenId] = useState();
  const [web3, setWeb3] = useState(new Web3(window.ethereum));

  useEffect(() => {
    setNftFilteredDetails(props?.listedNft);
  }, [props]);

  // setting rpc url
  useEffect(() => {
    if (collectionDetails?.selectedNetwork === config.networks.songbird) {
      setWeb3(new Web3(config.rpc.songbird));
    } else {
      setWeb3(new Web3(config.rpc.flr));
    }
  }, [collectionDetails]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  const navigateToSingleArtwork = (item) => {
    try {
      let collectionId = item?.data.mintContract;
      let tokenId = item?.data.tokenId;
      let network = networkType(item?.metadata?.data?.selectedBlockchain);
      navigate(`/artwork/${network}/${collectionId}/${tokenId}/`);
    } catch (error) {
      console.log(error);
    }
  };

  // get nfts of collected user (return token id )

  const getUserNftsFromContract = async () => {
    let web3_1;
    if (collectionDetails?.selectedNetwork === config.networks.songbird) {
      web3_1 = new Web3(config.rpc.songbird);
    } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
      web3_1 = new Web3(config.rpc.flr);
    }

    try {
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );
        const contractOwner = await contract.methods.owner().call();

        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenId(collectionDetails?.address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.log(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    resolve(a.tokenId);
                  }
                } else {
                  
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // get token ids of nfts that are not listed

  const getTokenIdforNoList = async () => {
    try {
      let TokenId = await getUserNftsFromContract();
      let tokenId = TokenId;
      return tokenId;
    } catch (error) {
      console.log(error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    const getToken = async () => {
      let res = await getTokenIdforNoList();

      setTokenId(res);
    };

    getToken();
  }, [collectionDetails]);

  return (
    <div>
      <div
        className={
          props.flag ? "d-flex align-item-center justify-content-between" : ""
        }
      >
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          <AsideFilterExplore
            onSelectedFilterChange={props.onSelectedFilterChange}
          />
        </div>
        <div
          className={`grid-display layout-artwork  ${
            props.flag ? "w-75 active" : ""
          }`}
        >
          {nftFilteredDetails &&
            nftFilteredDetails.map((item, index) => {
              return (
                <div
                  onClick={() => navigateToSingleArtwork(item)}
                  className="collection-grid-card"
                  key={index}
                >
                  <div className="card-body">
                    <div className="art-img">
                      {item?.metadata?.data?.image ? (
                        <img
                          src={item?.metadata?.data?.image}
                          alt="art"
                          className="img-100 artwork-hover"
                        />
                      ) : (
                        <img
                          src={item?.metadata?.data?.previewImg}
                          className="img-100 artwork-hover"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
