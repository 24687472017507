import React, { useEffect, useState, useContext } from "react";
import "../../style/main.scss";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import { NFTContext } from "../context/appContext";
import { getAllLikedArtwork, getAllUsers } from "../../firebase/firebase"; // Import the function to fetch favorite documents
import LikesGrid from "./likesGrid";
import { fetchNFTMetadata } from "../../Services/nft";
const Likes = (props) => {
  const [accountAddress, setAccountAddress] = useState();
  const [favoriteData, setFavoriteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const { listedNfts, updateItemNumbers, itemNumberLike } =
    useContext(NFTContext);

  useEffect(() => {
    setAccountAddress(props?.address);
  }, [props]);

  const [address, setAddress] = useState(null);
  const updateAddressFromProps = () => {
    if (props?.id) {
      setAddress(props?.id);
    } else {
      setAddress(accountAddress);
    }
  };

  useEffect(() => {
    updateAddressFromProps();
  }, [props]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const users = await getAllUsers();
        setUserData(users);
      } catch (error) {
        return;
      }
    };
    fetchUserData();
  }, []);

  // get nft metadata from backend

  const getNftMetadata = async (tokens_uri) => {
    const json = await fetchNFTMetadata(tokens_uri);
    return {
      uri: tokens_uri,
      data: json.data,
    };
  };

  // get all liked artworks from firebase

  useEffect(() => {
    async function fetchLikedArtworks() {
      try {
        setLoading(true);

        let res;
        if (props?.address) {
          res = await getAllLikedArtwork(props.address);
        } else if (props?.id) {
          res = await getAllLikedArtwork(props.id);
        }

        if (res && res.likedArtworks.length > 0) {
          const likedArtworks = res.likedArtworks;
          const nftPromises = likedArtworks.map((a) => getNftMetadata(a));
          const nftData = await Promise.all(nftPromises);

          setFavoriteData(nftData);
        } else {
          setFavoriteData([]);
        }

        setLoading(false);
      } catch (error) {
        console.log("Error fetching liked artworks:", error);
        setLoading(false);
      }
    }

    fetchLikedArtworks();
  }, [props.address, props.id]);

  const navigate = useNavigate();
  const exploreArts = () => {
    navigate("/explore");
  };

  // set listed data with each liked artwork
  const getNamebyAddress = () => {
    if (props?.address && !props?.id) {
      return "You haven't ";
    } else {
      const user = userData?.find((user) => user?.id === props?.id);
      const artistName = user ? user?.userName : null;

      if (!artistName) {
        return "@" + props?.id + " hasn't ";
      } else {
        const truncatedName =
          artistName.length > 18
            ? artistName.substring(0, 18) + "..."
            : artistName;
        return "@" + truncatedName + " hasn't ";
      }
    }
  };

  return (
    <>
      {!loading && itemNumberLike == 0 ? (
        <div className="no-content mt-5 pt-5  ">
          <p className="body-large">
            {getNamebyAddress()} <span>liked </span>
            any artwork yet.
          </p>
          <Button
            text="Explore art"
            width="166px"
            height="36px"
            className="btn-prime btn-primary"
            onClick={exploreArts}
          />
        </div>
      ) : (
        <div className="art-likes">
          <LikesGrid
            address={address}
            listedNft={favoriteData}
            id={props.id}
            loading={loading}
          />
        </div>
      )}
    </>
  );
};

export default Likes;
