const environment = "production";

// const firebaseConfig =
//   environment === "production"
//     ? {
//         apiKey: process.env.REACT_APP_APIKEY_PRODUCTION,
//         authDomain: process.env.REACT_APP_AUTHDOMAIN_PRODUCTION,
//         projectId: process.env.REACT_APP_PROJECTID_PRODUCTION,
//         storageBucket: process.env.REACT_APP_STORAGEBUCKET_PRODUCTION,
//         messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID_PRODUCTION,
//         appId: process.env.REACT_APP_APPID_PRODUCTION,
//         measurementId: process.env.REACT_APP_MEASUREMENTID_PRODUCTION,
//       }
//     : {
//         apiKey: process.env.REACT_APP_APIKEY_LOCAL,
//         authDomain: process.env.REACT_APP_AUTHDOMAIN_LOCAL,
//         projectId: process.env.REACT_APP_PROJECTID_LOCAL,
//         storageBucket: process.env.REACT_APP_STORAGEBUCKET_LOCAL,
//         messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID_LOCAL,
//         appId: process.env.REACT_APP_APPID_LOCAL,
//         measurementId: process.env.REACT_APP_MEASUREMENTID_LOCAL,
//       };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY_LOCAL,
  authDomain: process.env.REACT_APP_AUTHDOMAIN_LOCAL,
  projectId: process.env.REACT_APP_PROJECTID_LOCAL,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET_LOCAL,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID_LOCAL,
  appId: process.env.REACT_APP_APPID_LOCAL,
  measurementId: process.env.REACT_APP_MEASUREMENTID_LOCAL,
};

export default firebaseConfig;
